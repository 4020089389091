
import './src/styles/global.css';
import './src/styles/webfonts.css';
import './src/styles/plyr.css';

// Ensure widget CSS is loaded
if (typeof window !== "undefined" && !window.widgetCSSLoaded) {
  const link = document.createElement("link");
  link.href = "https://lineuppolo-docs-prod.s3.eu-west-2.amazonaws.com/widget/styles/matchWidgetStyles.css";
  link.rel = "stylesheet";
  document.head.appendChild(link);
  window.widgetCSSLoaded = true;

  const linkTournaments = document.createElement("link");
  linkTournaments.href = "https://lineuppolo-docs-prod.s3.eu-west-2.amazonaws.com/widget/tournamentWidget/tournamentWidgetStyles.css";
  linkTournaments.rel = "stylesheet";
  document.head.appendChild(linkTournaments);
  window.widgetCSSLoaded = true;
}

export const onRouteUpdate = ({ location }) => {
  window.scrollTo(0, 0);
};
