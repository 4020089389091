exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-en-js": () => import("./../../../src/pages/about.en.js" /* webpackChunkName: "component---src-pages-about-en-js" */),
  "component---src-pages-about-es-js": () => import("./../../../src/pages/about.es.js" /* webpackChunkName: "component---src-pages-about-es-js" */),
  "component---src-pages-es-js": () => import("./../../../src/pages/es.js" /* webpackChunkName: "component---src-pages-es-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-en-js": () => import("./../../../src/pages/news.en.js" /* webpackChunkName: "component---src-pages-news-en-js" */),
  "component---src-pages-news-es-js": () => import("./../../../src/pages/news.es.js" /* webpackChunkName: "component---src-pages-news-es-js" */),
  "component---src-pages-press-en-js": () => import("./../../../src/pages/press.en.js" /* webpackChunkName: "component---src-pages-press-en-js" */),
  "component---src-pages-press-es-js": () => import("./../../../src/pages/press.es.js" /* webpackChunkName: "component---src-pages-press-es-js" */),
  "component---src-pages-social-impact-en-js": () => import("./../../../src/pages/social-impact.en.js" /* webpackChunkName: "component---src-pages-social-impact-en-js" */),
  "component---src-pages-social-impact-es-js": () => import("./../../../src/pages/social-impact.es.js" /* webpackChunkName: "component---src-pages-social-impact-es-js" */),
  "component---src-pages-tournaments-en-js": () => import("./../../../src/pages/tournaments.en.js" /* webpackChunkName: "component---src-pages-tournaments-en-js" */),
  "component---src-pages-tournaments-es-js": () => import("./../../../src/pages/tournaments.es.js" /* webpackChunkName: "component---src-pages-tournaments-es-js" */),
  "component---src-templates-events-js": () => import("./../../../src/templates/events.js" /* webpackChunkName: "component---src-templates-events-js" */),
  "component---src-templates-legals-js": () => import("./../../../src/templates/legals.js" /* webpackChunkName: "component---src-templates-legals-js" */),
  "component---src-templates-news-js": () => import("./../../../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-templates-results-js": () => import("./../../../src/templates/results.js" /* webpackChunkName: "component---src-templates-results-js" */)
}

